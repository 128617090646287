/** @jsx jsx */
import { jsx } from 'theme-ui'

const Container = ({ background = 'inherit', ...props }) => (
  <div
    {...props}
    sx={{
      maxWidth: 'container',
      mx: 'auto',
      px: 3,
      // bg: `${background}`,
    }}
  />
)

export default Container
