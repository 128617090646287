import React from 'react'
import PropTypes from 'prop-types'
import useSiteMetadata from '../../hooks/use-site-metadata'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import SchemaOrg from './schema-org'

const SEO = ({ title, page, description, image, children }) => {
  const { pathname } = useLocation()

  const site = useSiteMetadata()
  const { siteTitle, siteUrl, siteDescription, siteLanguage, siteImage, author, siteKeywords } = site

  const twitterImage = '/images/twitter-og.png'
  const facebookImage = '/images/fb-og.png'

  const seo = {
    title: page === 'landing' ? `${title}` || siteTitle : `${title} | ${siteTitle}` || siteTitle,
    description: description || siteDescription,
    keywords: siteKeywords,
    image: `${siteUrl}${image || siteImage}`,
    imageTwitter: `${siteUrl}${twitterImage || siteImage}`,
    imageFacebook: `${siteUrl}${facebookImage || siteImage}`,
    url: `${siteUrl}${pathname || siteUrl}`,
  }

  return (
    <>
      <Helmet>
        {/* General tags */}
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="keywords" content={seo.keywords} />
        <link rel="canonical" href={seo.url} />
        <html lang={siteLanguage} />

        {/* OpenGraph tags */}
        <meta property="og:title" content={seo.title} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.imageFacebook} />
        <meta property="og:type" content="website" />
        <meta property="og:image:alt" content={seo.description} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:url" content={seo.url} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.imageTwitter} />
        <meta name="twitter:image:alt" content={seo.description} />
        <meta name="twitter:creator" content={author} />

        {children}
      </Helmet>
      <SchemaOrg title={seo.title} defaultTitle={siteTitle} url={seo.url} />
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  children: null,
}
